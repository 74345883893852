import { type App, getCNFContext } from '@evenfinancial/app-utils'
import { Analytics } from '@segment/analytics-node'

export class MissingSegmentWriteKeyError extends Error {
  constructor() {
    super('No Segment write key found')
    this.name = 'MissingSegmentWriteKeyError'
  }
}

export class MissingDeviceIdError extends Error {
  constructor() {
    super('No device ID found')
    this.name = 'MissingDeviceIdError'
  }
}

/**
 * Initialize the Segment client with the write key.
 * @returns The Segment client instance.
 * @throws Error if the Segment write key is not set.
 */
export const initClient = () => {
  const key = process.env.SEGMENT_WRITE_KEY

  const verifiedKey = verifySegmentWriteKey(key)

  return new Analytics({ writeKey: verifiedKey })
}

/**
 * Get default properties to send with each event.
 */
export const getDefaultProperties = () => {
  const {
    channel,
    zone,
    // @ts-ignore waiting for package update
    cnfAppBaseUrl: currentPageUrl,
  } = getCNFContext().use().context.request

  const app = getCNFContext().use().context.configuration.app as App

  // remove 'apps/' prefix - when runs locally, the app name is prefixed with '@apps/'
  const appName = app?.name.replace(/(@?apps\/?)/g, '')

  // @ts-ignore waiting for package update
  const deviceId = getCNFContext().use()?.context?.signals?.deviceSignals?.id

  verifyDeviceId(deviceId)

  return { channel, zone, appName, deviceId, currentPageUrl }
}

/**
 * Verify that the Segment write key is set.
 * @param key - The Segment write key.
 * @returns The verified Segment write key.
 * @throws MissingSegmentWriteKeyError if the Segment write key is not set.
 */
export const verifySegmentWriteKey = (key: string | undefined) => {
  if (!key) {
    console.error(
      'No Segment write key found. Please set the SEGMENT_WRITE_KEY environment variable or provide it in the configuration context.',
    )
    throw new MissingSegmentWriteKeyError()
  }
  return key
}

/**
 * Verify that the device ID is set.
 * @param deviceId - The device ID.
 * @returns The verified device ID.
 * @throws MissingDeviceIdError if the device ID is not set.
 */
export const verifyDeviceId = (deviceId: string | undefined) => {
  if (!deviceId) {
    console.error('No device ID found. Please provide it in the configuration context.')
    throw new MissingDeviceIdError()
  }
  return deviceId
}
