'use client'

import { useRouter, useSearchParams } from 'next/navigation'

const SEARCH_PARAMS_TO_BE_PRESERVED = ['lead-uuid', 'iframeId']

export const buildUrlWithSearchParams = (
  url: string,
  searchParams: URLSearchParams,
  preservedParams: string[] = SEARCH_PARAMS_TO_BE_PRESERVED,
) => {
  const currentParams = new URLSearchParams()

  preservedParams.forEach((param: string) => {
    const value = searchParams.get(param)
    if (value) {
      currentParams.set(param, value)
    }
  })

  const queryString = currentParams.toString()
  const newUrl = queryString ? `${url}?${queryString}` : url
  return newUrl
}

export const useNavigationWithSearchParams = (
  preservedParams: string[] = SEARCH_PARAMS_TO_BE_PRESERVED,
) => {
  const { push } = useRouter()
  const searchParams = useSearchParams()

  const navigateWithParams = (url: string) => {
    push(buildUrlWithSearchParams(url, searchParams, preservedParams))
  }

  return { navigateWithParams }
}
